import React from 'react'
import Image from '~/components/atoms/Image'
import TextLink from '~/components/atoms/TextLink'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Feature({className, imageClassName, heading, content, link, image}) {
  return (
    <div className={` ${className}`}>
      <FadeInWhenVisible>
        <Image data={image} className={`w-full h-auto mb-10 ${imageClassName}`} />
      </FadeInWhenVisible>
      <FadeInWhenVisible transition={{delay: '.05'}}>
        <h5 className="text-h5 mb-3">{heading}</h5>
      </FadeInWhenVisible>
      <FadeInWhenVisible transition={{delay: '.1'}}>
        <p>{content}</p>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        {link && <TextLink className="mt-4" {...link} />}
      </FadeInWhenVisible>
    </div>
  )
}
