import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Feature from '~/components/molecules/Feature'

export default function Features({key, index, ...props}) {

  const section = props.features
  const layout = section.layout
  
  return (
    <section id={section.fieldGroupName + index}  className="my-20 md:my-36 overflow-hidden">
      <div className="container">
        <div className="md:grid grid-cols-12 mb-16 md:mb-40">
          <TextCard className={`text-center ${layout === '1' ? 'col-start-1 col-span-5 md:text-left' : 'col-span-6 col-start-4 '} `} {...section.textCard}/>
        </div>
        <div className="md:grid grid-cols-12 ">
          <div className={` grid md:grid-cols-5 ${layout === '3' ? 'col-span-6' : 'col-span-5'}`}>
            {section.features[0] && <Feature className={`col-span-4 col-start-2 mb-16 md:mb-28 ${layout === '2' && 'md:-mt-14'}`} imageClassName="" {...section.features[0]} />}
            {section.features[2] && <Feature className={`col-start-1 mb-16 md:mb-0 ${layout === '2' ? 'col-span-5' : 'col-span-4'}`} imageClassName="-ml-8 w-[calc(100%+2rem)] md:ml-0 md:w-full" {...section.features[2]}/>}
          </div>
          <div className={`grid grid-cols-5 ${layout === '1' ? 'md:-mt-80' : layout === '2' ? 'mt-16' : 'md:-mt-16'} ${layout === '2' ? 'col-span-6 col-start-7' : 'col-span-5 col-start-8'}`}>
            {section.features[1] && <Feature className={`col-start-2 mb-16  ${layout === '2' ? 'md:col-start-2' : 'md:col-start-1'} col-span-4 md:mb-20 xl:mb-48`} imageClassName="-mr-8 w-[calc(100%+2rem)] md:mr-0 md:w-full" {...section.features[1]}/>}
            {section.features[3] && <Feature className={`col-span-4 ${(layout === '3' || layout === '2') ? '' : 'md:col-span-5'}`} imageClassName="-ml-8 w-[calc(100%+2rem)] md:ml-0 md:w-full"  {...section.features[3]} />}
          </div>
        </div>
      </div>
    </section>
  )
}
